<template>
  <v-form ref="form" v-model="isFormValid" :disabled="loading" @submit.prevent="submitForm">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <house-funds-selector
            label="Fundo da Casa"
            hint="Clique para selecionar"
            append-icon="mdi-home-currency-usd"
            persistent-hint
            autofocus
            item-text="shortName"
            item-value="_id"
            v-model="formFields.houseFundId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Operação"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.refDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Liquidação"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.settlementDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            label="Operação"
            hint="Clique para selecionar"
            append-icon="mdi-swap-horizontal-bold"
            ref="operation"
            persistent-hint
            v-model="formFields.operation"
            :items="operationTypeOptions"
            @change="calculateValue"
            @focus="showOperationOptions"
            @mousedown.prevent
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Book"
            append-icon="mdi-book-variant"
            v-model="formFields.book"
            v-maska="vMask.alphanumericUnderscored"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Contraparte"
            hint="Código da corretora"
            append-icon="mdi-bank"
            v-model.trim="formFields.counterpart"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <bonds-selector
            label="Título"
            hint="Nome, código ou ISIN do título"
            append-icon="mdi-trending-up"
            persistent-hint
            return-object
            item-text="reportName"
            item-value="_id"
            v-model="formFields.bondId"
            :rules="[vRules.requiredField]"
            @input="selectedBond = $event"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Data do Vencimento"
            hint="Impossível editar"
            append-icon="mdi-calendar-clock"
            tabindex="-1"
            class="dashed-line"
            readonly
            :persistent-hint="!!formFields.maturity"
            :value="formFields.maturity"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Taxa"
            suffix="%"
            :value="formFields.rate"
            @input="formFields.rate = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Quantidade"
            append-icon="mdi-counter"
            :rules="[vRules.requiredField]"
            :options="vOptions.positive"
            :value="formFields.quantity"
            @input="formFields.quantity = vMask.unmaskNumber($event)"
            @blur="calculateValue"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Preço"
            append-icon="mdi-cash"
            persistent-hint
            :rules="[vRules.requiredField]"
            :options="vOptions.positive"
            :value="formFields.price"
            @input="formFields.price = vMask.unmaskNumber($event)"
            @blur="calculateValue"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Valor"
            append-icon="mdi-cash-multiple"
            tabindex="-1"
            outlined
            readonly
            :value="formFields.value"
            :options="vOptions.value"
            @input="formFields.value = vMask.unmaskNumber($event)"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn text block color="error" :disabled="loading" @click="discardHandler">
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Decimal from 'decimal.js';
import moment from 'moment-loyall';

import fillSchema from '@/utils/fill-schema';
import { alphanumericUnderscored, unmaskNumber } from '@/utils/mask-utils';
import { parseDate, parseFromPercentage } from '@/utils/parse-utils';
import { requiredField, validDate } from '@/utils/validators';

import BondsSelector from '@/components/global/BondsSelector.vue';
import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  houseFundId: null,
  refDate: null,

  name: '',
  maturity: null,
  settlementDate: null,

  book: '',
  operation: 'BUY',
  counterpart: '',

  quantity: null,
  price: null,
  value: null,
  rate: null,

  bondId: null,
};

export default {
  name: 'BondsOperationsForm',

  components: {
    DatePickerFieldV2,
    HouseFundsSelector,
    BondsSelector,
    NumericInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData),
    isFormValid: false,
    selectedBond: null,

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
      alphanumericUnderscored,
    },

    vOptions: {
      value: {
        autoDecimalDigits: true,
        precision: 2,
      },

      positive: {
        valueRange: { min: 0 },
      },
    },

    operationTypeOptions: [
      'BUY',
      'SELL',
      'DEPOSIT',
      'WITHDRAWAL',
      'PAYOUT',
    ],

    operationValueSign: {
      BUY: -1,
      SELL: 1,
      DEPOSIT: 1,
      WITHDRAWAL: -1,
      PAYOUT: 1,
    },
  }),

  watch: {
    initialData: {
      immediate: true,
      handler(updatedInitialData) {
        this.formFields = fillSchema(formSchema, updatedInitialData);
        this.calculateValue();
      },
    },

    selectedBond(newSelection) {
      const { _id, name, expirationDate } = newSelection ?? {};

      this.formFields = fillSchema(formSchema, {
        ...this.formFields,
        name,
        bondId: _id,
        maturity: parseDate(expirationDate),
      });
    },
  },

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText || 'Descartar alterações',
      submitBtn: vm.submitBtnText || 'Enviar',
    }),
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    calculateValue() {
      const quantity = this.formFields.quantity ?? 0;
      const price = this.formFields.price ?? 0;
      const sign = this.operationValueSign[this.formFields.operation];

      this.formFields.value = new Decimal(sign)
        .times(quantity)
        .times(price)
        .add(0) // Forcefully converts -0 to 0
        .toNumber();
    },

    showOperationOptions() {
      this.$refs.operation.activateMenu();
    },

    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    submitForm() {
      this.$emit('submit', {
        ...this.formFields,
        rate: parseFromPercentage(this.formFields.rate),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dashed-line .v-input__slot:before {
    border-style: dashed !important;
  }
}
</style>
