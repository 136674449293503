import { sumBy, uniq } from 'lodash';
import { getSignedValue } from './common-utils';

type ShareOperation = Record<string, any>;

export function calculateShareCosts(item: ShareOperation) {
  const {
    brokerage = 0,
    registryFees = 0,
    notaryFees = 0,
  } = item;

  return brokerage + registryFees + notaryFees;
}

export function getSharesOverview(operations: ShareOperation[]) {
  return {
    totalCount: operations.length,
    totalQuantity: sumBy(operations, (item) => getSignedValue(item.quantity ?? 0, item.operation)),
    totalCosts: sumBy(operations, (item) => calculateShareCosts(item)),
    totalValue: sumBy(operations, 'value'),
  };
}

export function getSharesFilterOptions(operations: ShareOperation[]) {
  return {
    ticker: uniq(operations.map((item) => item.ticker)).sort(),
    book: uniq(operations.map((item) => item.book)).sort(),
    operation: uniq(operations.map((item) => item.operation)).sort(),
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function filterSharesResults(results: Record<string, any>[], filters: Record<string, any>) {
  return results.map((row) => {
    const { operationsArray } = row;

    return {
      ...row,
      operationsArray,
      count: operationsArray.length,
      totalValue: sumBy(operationsArray, 'value'),
      currencies: uniq(operationsArray.map((item: any) => item.currency)),
    };
  }).filter((item) => item.operationsArray.length);
}
