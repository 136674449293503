<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="key"
    show-expand
  >
    <template v-slot:item.settlementDate="{ item }">
      {{ formatDate(item.settlementDate) }}
    </template>

    <template v-slot:item.count="{ item }">
      {{ formatNumber(item.count) }}
    </template>

    <template v-slot:item.open="{ item }">
      <span :class="{ 'error--text': item.open < 0 }">
        {{ formatMoney(item.open) }}
      </span>
    </template>

    <template v-slot:item.close="{ item }">
      <span :class="{ 'error--text': item.close < 0 }">
        {{ formatMoney(item.close) }}
      </span>
    </template>

    <template v-slot:item.totalValue="{ item }">
      <template v-if="item.currencies.length > 1">
        {{ `Distribuído em ${item.currencies.length} moedas` }}
      </template>

      <template v-else>
        <span :class="{ 'error--text': item.totalValue < 0 }">
          {{ formatCurrency(item.totalValue, item.currencies[0]) }}
        </span>
      </template>
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-3">
        <slot name="details" :item="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import {
  formatCurrency,
  formatDate,
  formatMoney,
  formatNumber,
} from '@/utils/format-utils';

export default {
  name: 'CashSettlementsStatementsTable',

  inheritAttrs: false,

  props: {
    hideValue: {
      type: Boolean,
      default: false,
    },

    showReturnValue: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        value: 'name',
        text: 'Fundo',
      },

      {
        value: 'settlementDate',
        text: 'Vencimento',
      },

      {
        value: 'open',
        text: 'Abertura',
        align: 'end',
      },

      {
        value: 'close',
        text: 'Fechamento',
        align: 'end',
      },

      {
        value: 'count',
        text: 'Nº operações',
        align: 'end',
      },

      {
        value: 'totalValue',
        text: 'Valor total',
        align: 'end',
      },

      {
        value: 'data-table-expand',
        text: '',
      },
    ],
  }),

  methods: {
    formatCurrency,
    formatDate,
    formatMoney,
    formatNumber,
  },
};
</script>
