<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="_id"
    show-expand
  >

    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        icon
        color="error"
        v-show="allowBulkDelete"
        :disabled="isFiltered"
        @click="() => $emit('click:delete', item)"
      >
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <template v-slot:item.refDate="{ item }">
      {{ formatDate(item.refDate) }}
    </template>

    <template v-slot:item.count="{ item }">
      {{ formatNumber(item.count) }}
    </template>

    <template v-slot:item.totalValue="{ item }">
      <template v-if="item.currencies.length > 1">
        {{ `Distribuído em ${item.currencies.length} moedas` }}
      </template>

      <template v-else>
        <span :class="{ 'error--text': item.totalValue < 0 }">
          {{ formatCurrency(item.totalValue, item.currencies[0]) }}
        </span>
      </template>
    </template>

    <template v-slot:item.totalReturnValue="{ item }">
      <template v-if="item.currencies.length > 1">
        {{ `Distribuído em ${item.currencies.length} moedas` }}
      </template>

      <template v-else>
        {{ formatCurrency(item.totalReturnValue, item.currencies[0]) }}
      </template>
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-3">
        <slot name="details" :item="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import {
  formatCurrency,
  formatDate,
  formatMoney,
  formatNumber,
} from '@/utils/format-utils';

export default {
  name: 'AssetsOperationsTable',

  inheritAttrs: false,

  props: {
    hideValue: {
      type: Boolean,
      default: false,
    },

    showReturnValue: {
      type: Boolean,
      default: false,
    },

    refDateLabel: {
      type: String,
      default: 'Data da operação',
    },

    isFiltered: {
      type: Boolean,
      default: false,
    },

    allowBulkDelete: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rawHeaders: (vm) => [
      {
        value: 'actions',
        text: 'Apagar',
        width: '40px',
        sortable: false,
        groupable: false,
      },

      {
        value: 'name',
        text: 'Fundo',
      },

      {
        value: 'refDate',
        text: vm.refDateLabel,
      },

      {
        value: 'count',
        text: 'Nº operações',
        align: 'right',
      },

      {
        value: 'totalValue',
        text: 'Valor total',
        align: 'right',
      },

      {
        value: 'totalReturnValue',
        text: 'Valor total de retorno',
        align: 'right',
      },

      {
        value: 'data-table-expand',
        text: '',
      },
    ],

    headers: (vm) => vm.rawHeaders.filter((item) => {
      if (vm.hideValue && item.value === 'totalValue') {
        return false;
      }

      if (!vm.showReturnValue && item.value === 'totalReturnValue') {
        return false;
      }

      if (!vm.allowBulkDelete && item.value === 'actions') {
        return false;
      }

      return true;
    }),
  },

  methods: {
    formatCurrency,
    formatDate,
    formatMoney,
    formatNumber,
  },
};
</script>
