import { sumBy, uniq } from 'lodash';
import { formatDate } from '@/utils/format-utils';
import { getSignedValue } from './common-utils';

type FundOperation = Record<string, any>;

export function getFundsOverview(operations: FundOperation[]) {
  return {
    totalCount: operations.length,
    totalQuantity: sumBy(operations, (item) => getSignedValue(item.quantity ?? 0, item.operation)),
    totalValue: sumBy(operations, (item) => Math.abs(item.value)),
  };
}

export function getFundsFilterOptions(operations: FundOperation[]) {
  return {
    name: uniq(operations.map((item) => item.name)).sort(),
    book: uniq(operations.map((item) => item.book)).sort(),
    operation: uniq(operations.map((item) => item.operation)).sort(),
    refDate: uniq(operations.map((item) => item.refDate)).sort().reverse().map(
      (value) => ({ value, text: formatDate(value) }),
    ),
  };
}

export function filterFundsResults(results: Record<string, any>[], filters: Record<string, any>) {
  return results.map((row, index) => {
    const operationsArray: FundOperation[] = row.operationsArray.filter((operation: Record<string, any>) => {
      const rules: boolean[] = [
        !filters.name.length || filters.name.includes(operation.name),
        !filters.book.length || filters.book.includes(operation.book),
        !filters.operation.length || filters.operation.includes(operation.operation),
        !filters.refDate.length || filters.refDate.includes(operation.refDate),
      ];

      return rules.every((rule) => rule);
    });

    return {
      ...row,
      key: `${row.houseFundId}_${index}_${row.refDate ?? row.registerDate}`,
      operationsArray,
      count: operationsArray.length,
      totalValue: sumBy(operationsArray, (item) => Math.abs(item.value)),
      currencies: uniq(operationsArray.map((item: any) => item.currency)),
    };
  }).filter((item) => item.operationsArray.length);
}
