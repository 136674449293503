<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <house-funds-selector
            label="Fundo"
            hint="Campo obrigatório"
            persistent-hint

            item-text="shortName"
            item-value="_id"

            v-model.trim="formFields.houseFundId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <date-picker-field-v2
            label="Data (refDate)"
            :hint="formFields.refDate ? 'Utilize o formato YYYY-MM-DD' : 'Campo obrigatório'"
            :persistent-hint="!formFields.refDate"

            v-model.trim="formFields.refDate"
            :rules="[vRules.requiredField, vRules.validDate]"

            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Lastro (bondId)"

            v-model.trim="formFields.coverage.bondId"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Lastro (nome)"

            v-model.trim="formFields.coverage.name"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Lastro (vencimento)"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.coverage.maturity"
            :rules="[vRules.validDate]"

            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Book"

            v-maska="vMask.uppercased"
            v-model.trim="formFields.book"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data de retorno"
            :hint="formFields.returnDate ? 'Utilize o formato YYYY-MM-DD' : 'Campo obrigatório'"
            :persistent-hint="!formFields.returnDate"

            v-model="formFields.returnDate"
            :rules="[vRules.requiredField, vRules.validDate]"

            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Valor de retorno"
            hint="Campo obrigatório"
            persistent-hint

            :value="formFields.returnValue"
            :options="{ autoDecimalDigits: true, precision: 2 }"
            :rules="[vRules.requiredField]"
            @input="formFields.returnValue = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Liquidação"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.settlementDate"
            :rules="[vRules.validDate]"

            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Contraparte"

            v-model="formFields.counterpart"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            label="Operação"

            v-model.trim="formFields.operation"
            :items="operationTypeOptions"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="Quantidade"

            :value="formFields.quantity"
            :options="{ precision: 0 }"
            @input="formFields.quantity = vMask.unmaskNumber($event)"
            @blur="() => calculateValue()"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="Preço"

            :value="formFields.price"
            :options="{ valueRange: { min: 0 } }"
            @input="formFields.price = vMask.unmaskNumber($event)"
            @blur="() => calculateValue()"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="Valor"

            :value="formFields.value"
            :options="{ autoDecimalDigits: true, precision: 2 }"
            @input="formFields.value = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="Taxa"
            suffix="%"

            :value="formFields.rate"
            @input="formFields.rate = vMask.unmaskNumber($event)"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="loading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { round } from 'lodash';
import Decimal from 'decimal.js';
import moment from 'moment-loyall';

import { requiredField, validDate } from '@/utils/validators';
import { unmaskNumber, uppercased } from '@/utils/mask-utils';
import fillSchema from '@/utils/fill-schema';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  houseFundId: null,
  refDate: null,

  book: '',
  returnDate: null,
  returnValue: null,
  settlementDate: null,
  counterpart: '',
  operation: 'BUY',

  quantity: null,
  price: null,
  value: null,
  rate: null,

  // cashSettlementId: null,
  // returnCashSettlementId: null,
  // currency: 'BRL',

  coverage: {
    bondId: null,
    name: '',
    maturity: null,
  },
};

export default {
  name: 'RepurchaseOperationsForm',

  components: {
    DatePickerFieldV2,
    HouseFundsSelector,
    NumericInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData),
    isFormValid: false,

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
      uppercased,
    },

    operationTypeOptions: [
      'BUY',
      'SELL',
    ],
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText || 'Descartar alterações',
      submitBtn: vm.submitBtnText || 'Enviar',
    }),
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    calculateValue() {
      const { price, quantity } = this.formFields;

      if (quantity && price) {
        this.formFields.value = new Decimal(quantity).times(price).toNumber();
      }
    },

    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    submitForm() {
      const parseOptionalNumber = (val) => ((!val && val !== 0) ? null : Number(val));
      const parseOptionalPercentage = (val) => ((!val && val !== 0) ? null : round(val / 100, 10));

      this.$emit('submit', {
        ...this.formFields,

        quantity: parseOptionalNumber(this.formFields.quantity),
        price: parseOptionalNumber(this.formFields.price),
        value: parseOptionalNumber(this.formFields.value),
        rate: parseOptionalPercentage(this.formFields.rate),
        returnValue: parseOptionalNumber(this.formFields.returnValue),
      });
    },
  },
};
</script>
