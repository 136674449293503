import { formatDate } from '@/utils/format-utils';
import { sumBy, uniq } from 'lodash';
import { getSignedValue } from './common-utils';

type BondOperation = Record<string, any>;

export function getBondsOverview(operations: BondOperation[]) {
  return {
    totalCount: operations.length,
    totalQuantity: sumBy(operations, (item) => getSignedValue(item.quantity ?? 0, item.operation)),
    totalValue: sumBy(operations, 'value'),
  };
}

export function getBondsFilterOptions(operations: BondOperation[]) {
  return {
    name: uniq(operations.map((item) => item.name)).sort(),
    book: uniq(operations.map((item) => item.book)).sort(),
    operation: uniq(operations.map((item) => item.operation)).sort(),
    maturity: uniq(operations.map((item) => formatDate(item.maturity))).sort(),
  };
}

export function filterBondsResults(results: Record<string, any>[], filters: Record<string, any>) {
  return results.map((row) => {
    const operationsArray = row.operationsArray.filter((operation: Record<string, any>) => {
      const rules: boolean[] = [
        !filters.name.length || filters.name.includes(operation.name),
        !filters.book.length || filters.book.includes(operation.book),
        !filters.operation.length || filters.operation.includes(operation.operation),
        !filters.maturity.length || filters.maturity.includes(formatDate(operation.maturity)),
      ];

      return rules.every((rule) => rule);
    });

    return {
      ...row,
      operationsArray,
      count: operationsArray.length,
      totalValue: sumBy(operationsArray, 'value'),
      currencies: uniq(operationsArray.map((item: any) => item.currency)),
    };
  }).filter((item) => item.operationsArray.length);
}
