<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="_id"
    group-by="currency"
  >
    <template v-slot:group.header="{ group, headers, items }">
      <td :colspan="headers.length - 1">
        <v-btn small icon color="white" @click="() => $emit('click:filter')">
          <v-icon small>
            mdi-filter
          </v-icon>
        </v-btn>

        <span style="vertical-align: middle;">
          Moeda: {{ group }}
        </span>
      </td>

      <td :class="['text-right', { 'error--text': sumValues(items) < 0 }]">
        {{ formatCurrency(sumValues(items), group) }}
      </td>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn small icon color="primary" :disabled="readonly || item.origin !== 'manual'" @click="() => $emit('click:edit', item)">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>

      <v-btn small icon color="error" :disabled="readonly || item.origin !== 'manual'" @click="() => $emit('click:delete', item)">
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>

      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.refDate="{ item }">
      {{ formatDate(item.refDate) }}
    </template>

    <template v-slot:item.value="{ item }">
      <span :class="{ 'error--text': item.value < 0 }">
        {{ formatCurrency(item.value, item.currency) }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import Decimal from 'decimal.js';
import { formatCurrency, formatDate, formatMoney } from '@/utils/format-utils';
import copyToClipboard from '@/utils/copy-to-clipboard';

import { getCashSettlementsOverview } from './utils/cash-settlements-utils';

export default {
  name: 'CashSettlementsStatementsDetailsTable',

  inheritAttrs: false,

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '120px',
      },

      {
        value: 'description',
        text: 'Descrição',
      },

      {
        value: 'origin',
        text: 'Origem',
      },

      {
        value: 'refDate',
        text: 'Data da operação',
      },

      {
        value: 'value',
        text: 'Valor',
        align: 'end',
      },
    ],
  }),

  computed: {
    overview: (vm) => getCashSettlementsOverview(vm.$attrs.items ?? []),
  },

  methods: {
    formatCurrency,
    formatDate,
    formatMoney,

    sumValues: (items) => items.reduce((total, item) => total.plus(item.value), new Decimal('0')).toNumber(),

    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-row-group__header {
  background: #18324f !important;
  color: #fff;
  font-weight: 500;
}
</style>
