<template>
  <v-container fluid class="cash-settlements">
    <h1 class="text-h5">Lançamentos de Caixa</h1>

    <operations-base-filters :filters.sync="requestFilters" />

    <div class="text-right mb-4">
      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        @click="() => fetchData()"
      >
        <v-icon small left> mdi-refresh </v-icon>

        Atualizar
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        :disabled="!isElevatedUser"
        @click="() => showCreationDialog()"
      >
        <v-icon small left> mdi-plus </v-icon>

        Novo
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        :disabled="!isElevatedUser"
        @click="() => showBatchDialog()"
      >
        <v-icon small left>
          mdi-plus-box-multiple
        </v-icon>

        Lote
      </v-btn>
    </div>

    <v-card class="mb-4" :loading="isFetchingData">
      <v-card-title>
        Overview

        <v-spacer />
        <v-btn class="mr-2 pr-3" small rounded color="primary" :disabled="!hasResults" @click="downloadCsv">
          <v-icon small left> mdi-microsoft-excel </v-icon>
          Baixar
        </v-btn>

        <v-badge
          overlap
          color="info"
          :content="filterCount"
          :value="filterCount > 0"
        >
          <v-btn
            small
            rounded
            color="primary"
            class="mx-1"
            :disabled="!hasResults"
            @click="() => showFiltersDialog()"
          >
            <v-icon small left> mdi-filter </v-icon>

            Filtros
          </v-btn>
        </v-badge>
      </v-card-title>

      <cash-settlements-overview :overview="resultsOverview" />
    </v-card>

    <v-card>
      <v-card-text>
        <assets-operations-table
          fixed-header
          dense
          :items="filteredResults"
          :loading="isFetchingData"
          must-sort
          sort-by="refDate"
          sort-desc
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:details="{ item }">
            <v-card outlined>
              <cash-settlements-details-table
                fixed-header
                dense
                :items="item.operationsArray"
                :readonly="!isElevatedUser"
                must-sort
                :sort-by.sync="detailsSortBy"
                :sort-desc.sync="detailsSortDesc"
                hide-default-footer
                :items-per-page="-1"
                @click:filter="() => showFiltersDialog()"
                @click:edit="(op) => showEditionDialog(op)"
                @click:delete="(op) => showDeletionDialog(op)"
              />
            </v-card>
          </template>
        </assets-operations-table>
        <v-row v-if="showLoadMore" class="my-2" align="center" justify="space-around">
          <v-btn :loading="isFetchingData" @click="() => loadMore()" small color="primary">Carregar mais</v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="isBatchDialogOpen" max-width="800">
        <batch-operations-dialog
        title="Operações de Caixa em Lote"
        @click:cancel="hideBatchDialog"
        :headers='batchFields'
        @click:submit="(fields) => createItem(fields)"
        />
      </v-dialog>

    <v-dialog v-model="isFilterDialogOpen" max-width="400">
      <v-card>
        <v-card-title> Filtrar itens </v-card-title>

        <cash-settlements-filters
          :filters.sync="detailsFilters"
          :filter-options="filterOptions"
        />

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="() => hideFiltersDialog()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isCreationDialogOpen" max-width="600">
      <v-card>
        <v-card-title class="justify-center white--text primary">
          Novo Laçamento de Caixa
        </v-card-title>

        <br />

        <v-card-text class="py-0">
          <cash-settlements-form
            ref="creationForm"
            :loading="isSendingData"
            discard-btn-text="Cancelar"
            @click:discard="() => hideCreationDialog()"
            @submit="(fields) => createItem(fields)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isEditionDialogOpen" max-width="600">
      <v-card>
        <v-card-title class="justify-center white--text primary">
          Editar Laçamento de Caixa
        </v-card-title>

        <br />

        <v-card-text class="py-0">
          <cash-settlements-form
            ref="editionForm"
            :loading="isSendingData"
            :initial-data="selectedItem || {}"
            discard-btn-text="Cancelar"
            @click:discard="() => hideEditionDialog()"
            @submit="(fields) => editItem(fields)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <contextual-dialog
      v-model="isDeletionDialogOpen"
      icon="mdi-delete"
      :icon-color="isSendingData ? 'grey' : 'error'"
      :loading="isSendingData"
      title="Deletar operação?"
      message="Deseja realmente deletar a operação?"
    >
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :disabled="isSendingData"
          @click="() => deleteItem()"
        >
          Deletar
        </v-btn>
      </v-card-actions>
    </contextual-dialog>
  </v-container>
</template>

<script>
import { flatMap, debounce, isNil } from 'lodash';
import moment from 'moment-loyall';
import api from '@/services/api';
import { generateCSV } from '@/utils/json-to-csv';

import ContextualDialog from '@/components/global/ContextualDialog.vue';

import { parseRequestFilters, getApiErrors } from './utils/common-utils';

import {
  getCashSettlementsFilterOptions,
  getCashSettlementsOverview,
  filterCashSettlementsResults,
} from './utils/cash-settlements-utils';

import AssetsOperationsTable from './AssetsOperationsTable.vue';
import OperationsBaseFilters from './OperationsBaseFilters.vue';
import CashSettlementsDetailsTable from './CashSettlementsDetailsTable.vue';
import CashSettlementsFilters from './CashSettlementsFilters.vue';
import CashSettlementsForm from './CashSettlementsForm.vue';
import CashSettlementsOverview from './CashSettlementsOverview.vue';
import BatchOperationsDialog from './components/BatchOperationsDialog.vue';

export default {
  name: 'CashSettlements',

  components: {
    ContextualDialog,
    AssetsOperationsTable,
    OperationsBaseFilters,
    CashSettlementsDetailsTable,
    CashSettlementsFilters,
    CashSettlementsForm,
    CashSettlementsOverview,
    BatchOperationsDialog,
  },

  data: () => ({
    isFetchingData: false,
    results: [],
    total: 0,

    requestFilters: {
      houseFundIds: [],
      initialDate: moment()
        .businessSubtract(5, 'days', 'brasil')
        .format('YYYY-MM-DD'),
      finalDate: null,
    },

    isFilterDialogOpen: false,
    detailsFilters: {
      origin: [],
      description: [],
      settlementDate: [],
    },

    detailsSortBy: 'settlementDate',
    detailsSortDesc: true,

    selectedItem: null,
    isSendingData: false,

    isCreationDialogOpen: false,
    isEditionDialogOpen: false,
    isDeletionDialogOpen: false,

    isBatchDialogOpen: false,
    batchFields: [
      'houseFundId',
      'description',
      'refDate',
      'settlementDate',
      'value',
      'currency',
      'origin',
    ],
  }),

  computed: {
    isElevatedUser: (vm) => ['admin', 'backoffice'].includes(vm.$store.state.user?.acl),

    hasResults: (vm) => vm.results.length > 0,
    flatResults: (vm) => flatMap(vm.results, 'operationsArray'),

    filterCount: (vm) => Object.values(vm.detailsFilters).filter((val) => (Array.isArray(val) ? val.length > 0 : !!val)).length,
    filterOptions: (vm) => getCashSettlementsFilterOptions(vm.flatResults),
    filteredResults: (vm) => filterCashSettlementsResults(vm.results, vm.detailsFilters),
    flatFilteredResults: (vm) => flatMap(vm.filteredResults, 'operationsArray'),

    resultsOverview: (vm) => getCashSettlementsOverview(vm.flatFilteredResults),
    showLoadMore: (vm) => vm.total > vm.filteredResults.length,
  },

  watch: {
    requestFilters: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchData();
      },
    },

    isDeletionDialogOpen(newState) {
      if (!newState) {
        this.selectedItem = null;
      }
    },
  },

  created() {
    this.$store.dispatch('houseFunds/fetchList');
  },

  methods: {
    downloadCsv() {
      const data = this.filteredResults.map(({
        houseFundId, name, refDate, operationsArray,
      }) => operationsArray.map((operation) => {
        const cleanOperation = operation;

        delete cleanOperation._id;

        return {
          houseFundId,
          name,
          refDate,
          ...cleanOperation,
        };
      })).reduce((pre, cur) => pre.concat(cur));

      generateCSV(data);
    },

    showFiltersDialog() {
      this.isFilterDialogOpen = true;
    },

    hideFiltersDialog() {
      this.isFilterDialogOpen = false;
      this.fetchData();
    },

    showBatchDialog() {
      this.isBatchDialogOpen = true;
    },

    hideBatchDialog() {
      this.isBatchDialogOpen = false;
    },

    showEditionDialog(operation) {
      const operationData = { ...operation };

      if (operationData.refDate) {
        operationData.refDate = operationData.refDate.substring(0, 10);
      }

      if (operationData.settlementDate) {
        operationData.settlementDate = operationData.settlementDate.substring(
          0,
          10,
        );
      }

      this.selectedItem = operationData;

      this.isEditionDialogOpen = true;

      if (this.$refs.editionForm) {
        this.$nextTick(() => this.$refs.editionForm.discardChanges());
      }
    },

    hideEditionDialog() {
      this.isEditionDialogOpen = false;
      this.selectedItem = null;

      if (this.$refs.editionForm) {
        this.$nextTick(() => this.$refs.editionForm.discardChanges());
      }
    },

    showDeletionDialog(operation) {
      this.selectedItem = operation;
      this.isDeletionDialogOpen = true;
    },

    hideDeletionDialog() {
      this.isDeletionDialogOpen = false;
    },

    showCreationDialog() {
      this.isCreationDialogOpen = true;
    },

    hideCreationDialog() {
      this.isCreationDialogOpen = false;

      this.$nextTick(() => this.$refs.creationForm?.discardChanges());
    },

    async loadMore() {
      this.isFetchingData = true;

      try {
        const { data } = await api.operations.cashSettlements.getCashSettlements({
          params: {
            ...parseRequestFilters(this.requestFilters),
            ...this.detailsFilters,
            offset: this.filteredResults.length,
          },
        });
        this.total = data.total;
        this.results = [...this.results, ...data.operationsList] ?? [];
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    fetchData: debounce(async function debouncedFetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.operations.cashSettlements.getCashSettlements({
          params: { ...parseRequestFilters(this.requestFilters), ...this.detailsFilters },
        });
        this.total = data.total;
        this.results = data.operationsList ?? [];
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isFetchingData = false;
      }
    }, 200),

    async createItem(rawData) {
      this.isSendingData = true;

      try {
        const newData = {};

        // Remove empty values
        Object.entries(rawData).forEach(([key, value]) => {
          if (!isNil(value) && String(value).length > 0) {
            newData[key] = value;
          }
        });

        await api.operations.cashSettlements.createCashSettlement({
          operations: Array.isArray(rawData) ? rawData : [newData],
        });

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Operação adicionada com sucesso!',
          message: 'Os dados da operação foram inseridos corretamente.',
        });

        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível inserir a operação',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isSendingData = false;
      }
    },

    async editItem(newData) {
      this.isSendingData = true;

      try {
        await api.operations.cashSettlements.updateCashSettlement(
          this.selectedItem._id,
          newData,
        );

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Operação atualizada com sucesso!',
          message: 'Os dados da operação foram atualizados corretamente.',
        });

        this.fetchData();
        this.hideEditionDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível editar a operação',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isSendingData = false;
      }
    },

    async deleteItem() {
      this.isSendingData = true;

      try {
        await api.operations.cashSettlements.deleteCashSettlement(
          this.selectedItem._id,
        );

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Operação excluída com sucesso!',
          message: 'Os dados da operação foram removidos corretamente.',
        });

        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível deletar a operação',
          errorList: getApiErrors(error),
        });
      } finally {
        this.hideDeletionDialog();
        this.isSendingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>
