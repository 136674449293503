var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id","show-expand":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowBulkDelete),expression:"allowBulkDelete"}],attrs:{"small":"","icon":"","color":"error","disabled":_vm.isFiltered},on:{"click":function () { return _vm.$emit('click:delete', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.refDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.refDate))+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.count))+" ")]}},{key:"item.totalValue",fn:function(ref){
var item = ref.item;
return [(item.currencies.length > 1)?[_vm._v(" "+_vm._s(("Distribuído em " + (item.currencies.length) + " moedas"))+" ")]:[_c('span',{class:{ 'error--text': item.totalValue < 0 }},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.totalValue, item.currencies[0]))+" ")])]]}},{key:"item.totalReturnValue",fn:function(ref){
var item = ref.item;
return [(item.currencies.length > 1)?[_vm._v(" "+_vm._s(("Distribuído em " + (item.currencies.length) + " moedas"))+" ")]:[_vm._v(" "+_vm._s(_vm.formatCurrency(item.totalReturnValue, item.currencies[0]))+" ")]]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"pa-3",attrs:{"colspan":headers.length}},[_vm._t("details",null,{"item":item})],2)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }