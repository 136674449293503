<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th class="text-right">Total de operações</th>
        <th class="text-right">Valor total</th>
      </tr>
    </thead>

    <tbody class="grey lighten-2 font-weight-medium">
      <tr>
        <td class="text-right">
          {{ totalCountString }}
        </td>

        <td :class="['text-right', { 'error--text': overview.totalValue < 0 }]">
          {{ totalValueString }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { formatMoney, formatNumber } from '@/utils/format-utils';

export default {
  name: 'SharesOperationsOverview',

  props: {
    overview: {
      type: Object,
      required: true,
    },
  },

  computed: {
    totalCountString: (vm) => formatNumber(vm.overview.totalCount ?? 0),
    totalValueString: (vm) => formatMoney(vm.overview.totalValue ?? 0),
  },
};
</script>
