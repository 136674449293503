import { sumBy, uniq } from 'lodash';
import { getSignedValue } from './common-utils';

type RepurchaseOperation = Record<string, any>;

export function getRepurchaseOverview(operations: RepurchaseOperation[]) {
  return {
    totalCount: operations.length,
    totalQuantity: sumBy(operations, (item) => getSignedValue(item.quantity ?? 0, item.operation)),
    totalValue: sumBy(operations, 'value'),
    totalReturnValue: sumBy(operations, 'returnValue'),
  };
}

export function getRepurchaseFilterOptions(operations: RepurchaseOperation[]) {
  return {
    book: uniq(operations.map((item) => item.book)).sort(),
    operation: uniq(operations.map((item) => item.operation)).sort(),
  };
}

export function filterRepurchaseResults(results: Record<string, any>[], filters: Record<string, any>) {
  return results.map((row) => {
    const operationsArray = row.operationsArray.filter((operation: Record<string, any>) => {
      const rules: boolean[] = [
        !filters.book.length || filters.book.includes(operation.book),
        !filters.operation.length || filters.operation.includes(operation.operation),
      ];

      return rules.every((rule) => rule);
    });

    return {
      ...row,
      operationsArray,
      count: operationsArray.length,
      totalValue: sumBy(operationsArray, 'value'),
      totalReturnValue: sumBy(operationsArray, 'returnValue'),
      currencies: uniq(operationsArray.map((item: any) => item.currency)),
    };
  }).filter((item) => item.operationsArray.length);
}
