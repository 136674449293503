var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id","group-by":"currency"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var items = ref.items;
return [_c('td',{attrs:{"colspan":headers.length - 1}},[_c('v-btn',{attrs:{"small":"","icon":"","color":"white"},on:{"click":function () { return _vm.$emit('click:filter'); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-filter ")])],1),_c('span',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" Moeda: "+_vm._s(group)+" ")])],1),_c('td',{class:['text-right', { 'error--text': _vm.sumValues(items) < 0 }]},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.sumValues(items), group))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary","disabled":_vm.readonly || item.origin !== 'manual'},on:{"click":function () { return _vm.$emit('click:edit', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"error","disabled":_vm.readonly || item.origin !== 'manual'},on:{"click":function () { return _vm.$emit('click:delete', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipProps){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.copyId(item._id); }}},'v-btn',tooltipProps.attrs,false),tooltipProps.on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Copiar _id ")]},proxy:true}],null,true)})]}},{key:"item.refDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.refDate))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.value < 0 }},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.value, item.currency))+" ")])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }