<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="_id"
  >
    <template v-slot:body.prepend>
      <tr class="totals-row">
        <td>
          <v-btn small icon color="white" @click="() => $emit('click:filter')">
            <v-icon small>
              mdi-filter
            </v-icon>
          </v-btn>
        </td>

        <td colspan="4" />

        <td :class="['text-right', { 'error--text': overview.totalQuantity < 0 }]">
          {{ formatNumber(overview.totalQuantity) }}
        </td>

        <td colspan="3" />

        <td :class="['text-right', { 'error--text': overview.totalValue < 0 }]">
          {{ formatMoney(overview.totalValue) }}
        </td>
      </tr>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn small icon color="primary" :disabled="readonly" @click="() => $emit('click:edit', item)">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>

      <v-btn small icon color="error" :disabled="readonly" @click="() => $emit('click:delete', item)">
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>

      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.name="{ item }">
      <v-tooltip bottom content-class="grey darken-4">
        <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
          <div
            v-on="tooltipEvents"
            v-bind="tooltipAttrs"
            class="text-truncate"
            style="max-width: 220px"
          >
            {{ item.name }}
          </div>
        </template>

        <template v-slot:default>
          {{ item.name }}
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.quantity="{ item }">
      {{ formatNumber(item.quantity) }}
    </template>

    <template v-slot:item.conversionPrice="{ item }">
      {{ formatQuota(item.conversionPrice) }}
    </template>

    <template v-slot:item.refDate="{ item }">
      {{ formatDate(item.refDate) }}
    </template>

    <template v-slot:item.settlementDate="{ item }">
      {{ formatDate(item.settlementDate) }}
    </template>

    <template v-slot:item.value="{ item }">
      {{ formatMoney(Math.abs(item.value)) }}
    </template>
  </v-data-table>
</template>

<script>
import { formatDate, formatMoney, formatNumber } from '@/utils/format-utils';
import copyToClipboard from '@/utils/copy-to-clipboard';

import { getFundsOverview } from './utils/funds-operations-utils';

export default {
  name: 'FundsOperationsDetailsTable',

  inheritAttrs: false,

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '120px',
      },

      {
        value: 'name',
        text: 'Fundo',
      },

      {
        value: 'serial',
        text: 'CNPJ',
      },

      {
        value: 'book',
        text: 'Book',
      },

      {
        value: 'operation',
        text: 'Operação',
      },

      {
        value: 'quantity',
        text: 'Qtd. de cotas',
        align: 'end',
      },

      {
        value: 'conversionPrice',
        text: 'Valor da cota',
        align: 'end',
      },

      {
        value: 'refDate',
        text: 'Cotização',
      },

      {
        value: 'settlementDate',
        text: 'Liquidação',
      },

      {
        value: 'value',
        text: 'Valor',
        align: 'end',
      },
    ],
  }),

  computed: {
    overview: (vm) => getFundsOverview(vm.$attrs.items ?? []),
  },

  methods: {
    formatDate,
    formatMoney,
    formatNumber,
    formatQuota: (val) => formatNumber(val, { minimumFractionDigits: 8 }),

    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.totals-row td {
  background: #18324f;
  position: sticky;
  top: 32px;

  color: #fff;
  font-weight: 500;

  z-index: 2;
}
</style>
