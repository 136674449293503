var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',{staticClass:"totals-row"},[_c('td',[_c('v-btn',{attrs:{"small":"","icon":"","color":"white"},on:{"click":function () { return _vm.$emit('click:filter'); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-filter ")])],1)],1),_c('td',{attrs:{"colspan":"3"}}),_c('td',{class:['text-right', { 'error--text': _vm.overview.totalQuantity < 0 }]},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.overview.totalQuantity))+" ")]),_c('td'),_c('td',{class:['text-right', { 'error--text': _vm.overview.totalCosts < 0 }]},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.overview.totalCosts))+" ")]),_c('td',{class:['text-right', { 'error--text': _vm.overview.totalValue < 0 }]},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.overview.totalValue))+" ")])])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary","disabled":_vm.readonly},on:{"click":function () { return _vm.$emit('click:edit', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"error","disabled":_vm.readonly},on:{"click":function () { return _vm.$emit('click:delete', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipProps){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.copyId(item._id); }}},'v-btn',tooltipProps.attrs,false),tooltipProps.on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Copiar _id ")]},proxy:true}],null,true)})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.quantity < 0 }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.quantity))+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.price))+" ")]}},{key:"item.costs",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': _vm.calculateFutureOptionCosts(item) < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateFutureOptionCosts(item)))+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.value < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.value))+" ")])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }