<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          label="Ativo"
          hide-details

          multiple
          clearable
          small-chips
          deletable-chips

          :items="filterOptions.ticker"
          :value="filters.ticker"
          @change="(newValue) => updateFilters({ ticker: newValue })"
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
          label="Book"
          hide-details

          multiple
          clearable
          small-chips
          deletable-chips

          :items="filterOptions.book"
          :value="filters.book"
          @change="(newValue) => updateFilters({ book: newValue })"
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
          label="Operação"
          hide-details

          multiple
          clearable
          small-chips
          deletable-chips

          :items="filterOptions.operation"
          :value="filters.operation"
          @change="(newValue) => updateFilters({ operation: newValue })"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FuturesOperationsFilters',

  props: {
    filters: {
      type: Object,
      required: true,
    },

    filterOptions: {
      type: Object,
      required: true,
    },
  },

  watch: {
    filterOptions: {
      deep: true,
      immediate: true,
      handler(newOptions) {
        const validFilters = {};

        Object.entries(newOptions).forEach(([key, options]) => {
          validFilters[key] = this.filters[key].filter((val) => options.includes(val));
        });

        this.updateFilters(validFilters);
      },
    },
  },

  methods: {
    updateFilters(newValues) {
      this.$emit('update:filters', { ...this.filters, ...newValues });
    },
  },
};
</script>
