<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    @submit.prevent="() => submitForm()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="4">
          <house-funds-selector
            label="Fundo da Casa"
            hint="Clique para selecionar"
            append-icon="mdi-home-currency-usd"
            persistent-hint
            autofocus

            item-text="shortName"
            item-value="_id"

            v-model="formFields.houseFundId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Operação"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.refDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Liquidação"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.settlementDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            label="Operação"
            hint="Clique para selecionar"
            append-icon="mdi-swap-horizontal-bold"
            ref="operation"
            persistent-hint

            v-model="formFields.operation"
            :items="operationTypeOptions"
            @change="updateFormFields()"
            @focus="showOperationOptions"
            @mousedown.prevent
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Ativo"
            append-icon="mdi-trending-up"

            v-model="formFields.ticker"
            v-maska="vMask.alphanumericUnderscored"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Book"
            append-icon="mdi-book-variant"

            v-model="formFields.book"
            v-maska="vMask.alphanumericUnderscored"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Quantidade"
            append-icon="mdi-counter"

            :value="formFields.quantity"
            :options="vOptions.positive"
            @input="formFields.quantity = vMask.unmaskNumber($event)"
            @blur="() => calculateValue()"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Preço"
            append-icon="mdi-cash"

            :value="formFields.price"
            :options="vOptions.positive"
            @input="formFields.price = vMask.unmaskNumber($event)"
            @blur="() => calculateValue()"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-tooltip bottom tag="table">
            <template v-slot:activator="{ on }">
              <numeric-input
                label="Valor"
                hint="Passe o mouse para detalhes"
                append-icon="mdi-cash-multiple"
                tabindex="-1"
                persistent-hint
                outlined
                readonly

                v-on="on"
                :value="formFields.value"
                :options="vOptions.value"
                @input="formFields.value = vMask.unmaskNumber($event)"
              />
            </template>
            <tbody>
              <tr v-for="item in valueTooltip" :key="item.label">
                <td class="pr-3">{{ item.label }}</td>
                <td :class="`${item.color}--text`">{{ item.value }}</td>
              </tr>
            </tbody>
          </v-tooltip>
        </v-col>

        <v-row class="align-end px-6 mb-4" v-show="showCosts">
          <v-col class="px-0" cols="12" sm="12">
            Corretoras & Custos
            <v-divider />
          </v-col>

          <v-col cols="12" sm="4" class="rounded grey lighten-4">
            <div class="mb-2 text-right">Carrying</div>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Corretora"
                  hint="Código ou ID da corretora"
                  append-icon="mdi-bank-check"
                  dense

                  v-model.trim="formFields.brokerId"
                  :rules="[vRules.requiredField]"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <numeric-input
                  label="Corretagem"
                  append-icon="mdi-piggy-bank"
                  dense

                  :value="formFields.carryingBrokerage"
                  :options="vOptions.costs"
                  @input="formFields.carryingBrokerage = vMask.unmaskNumber($event)"
                  @blur="calculateValue()"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="4">
            <v-row>
              <v-col cols="12" sm="12">
                <numeric-input
                  label="Taxas de Registro"
                  append-icon="mdi-cash-register"
                  dense

                  :value="formFields.registryFees"
                  :options="vOptions.costs"
                  @input="formFields.registryFees = vMask.unmaskNumber($event)"
                  @blur="calculateValue()"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <numeric-input
                  label="Emolumentos"
                  append-icon="mdi-checkbook"
                  dense

                  :value="formFields.notaryFees"
                  :options="vOptions.costs"
                  @input="formFields.notaryFees = vMask.unmaskNumber($event)"
                  @blur="calculateValue()"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="4" class="rounded grey lighten-4">
            <div class="text-right">Executora</div>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Corretora"
                  hint="Código ou ID da corretora"
                  dense

                  v-model.trim="formFields.executionBrokerId"
                >
                  <template v-slot:append>
                    <v-icon class="text-h4">mdi-bank-transfer</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <numeric-input
                  label="Corretagem"
                  append-icon="mdi-piggy-bank"
                  dense

                  :value="formFields.executionBrokerage"
                  :options="vOptions.costs"
                  @input="formFields.executionBrokerage = vMask.unmaskNumber($event)"
                  @blur="calculateValue()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="9" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="loading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="9" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Decimal from 'decimal.js';
import moment from 'moment-loyall';

import fillSchema from '@/utils/fill-schema';
import { formatMoney } from '@/utils/format-utils';
import { alphanumericUnderscored, unmaskNumber } from '@/utils/mask-utils';
import { requiredField, validDate } from '@/utils/validators';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  houseFundId: null,
  refDate: null,
  settlementDate: null,

  operation: 'BUY',
  ticker: '',
  book: '',

  quantity: null,
  price: null,
  value: 0,

  brokerId: '85',
  carryingBrokerage: null,

  registryFees: null,
  notaryFees: null,

  executionBrokerId: null,
  executionBrokerage: null,

  brokerage: null,
};

export default {
  name: 'SharesOperationsForm',

  components: {
    DatePickerFieldV2,
    HouseFundsSelector,
    NumericInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },
  },

  data: (vm) => ({
    isFormValid: false,
    showCosts: true,

    formFields: fillSchema(formSchema, vm.initialData),

    supportFields: {
      grossValue: null,
      costs: null,
    },

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      alphanumericUnderscored,
      unmaskNumber,
    },

    vOptions: {
      costs: {
        autoDecimalDigits: true,
        precision: 2,
        valueRange: { max: 0 },
      },

      value: {
        autoDecimalDigits: true,
        precision: 2,
      },

      positive: {
        valueRange: { min: 0 },
      },
    },

    operationTypeOptions: [
      'BUY',
      'SELL',
      'DEPOSIT',
      'WITHDRAWAL',
      'PAYOUT',
    ],

    operationShowCosts: [
      'BUY',
      'SELL',
    ],

    operationValueSign: {
      BUY: -1,
      SELL: 1,
      DEPOSIT: 1,
      WITHDRAWAL: -1,
      PAYOUT: 1,
    },
  }),

  watch: {
    initialData: {
      immediate: true,
      handler(updatedInitialData) {
        this.formFields = fillSchema(formSchema, updatedInitialData);
        this.updateFormFields();
      },
    },
  },

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText || 'Descartar alterações',
      submitBtn: vm.submitBtnText || 'Enviar',
    }),

    valueTooltip: (vm) => ([
      {
        label: 'PU x Quantidade',
        value: formatMoney(vm.supportFields.grossValue),
        color: vm.supportFields.grossValue < 0 ? 'red' : 'green',
      },
      {
        label: 'Custo Total',
        value: formatMoney(vm.supportFields.costs),
        color: vm.supportFields.costs < 0 ? 'red' : 'green',
      },
    ]),
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    calculateGrossValue() {
      const quantity = this.formFields.quantity ?? 0;
      const price = this.formFields.price ?? 0;
      const sign = this.operationValueSign[this.formFields.operation];

      this.supportFields.grossValue = new Decimal(sign)
        .times(quantity)
        .times(price)
        .add(0) // Forcefully converts -0 to 0
        .toNumber();
    },

    calculateBrokerage() {
      const carryingBrokerage = this.formFields.carryingBrokerage ?? 0;
      const executionBrokerage = this.formFields.executionBrokerage ?? 0;

      this.formFields.brokerage = new Decimal(carryingBrokerage)
        .add(executionBrokerage)
        .toNumber();
    },

    calculateCosts() {
      this.calculateBrokerage();

      const brokerage = this.formFields.brokerage ?? 0;
      const notaryFees = this.formFields.notaryFees ?? 0;
      const registryFees = this.formFields.registryFees ?? 0;

      this.supportFields.costs = new Decimal(brokerage)
        .add(notaryFees)
        .add(registryFees)
        .toNumber();
    },

    calculateValue() {
      this.calculateGrossValue();
      this.calculateCosts();

      const grossValue = this.supportFields.grossValue ?? 0;
      const costs = this.supportFields.costs ?? 0;

      this.formFields.value = new Decimal(grossValue)
        .add(costs)
        .toNumber();
    },

    updateCostsFields() {
      this.showCosts = this.operationShowCosts.includes(this.formFields.operation);

      if (!this.showCosts) {
        this.formFields.brokerId = formSchema.brokerId;
        this.formFields.carryingBrokerage = formSchema.carryingBrokerage;

        this.formFields.executionBrokerId = formSchema.executionBrokerId;
        this.formFields.executionBrokerage = formSchema.executionBrokerage;

        this.formFields.brokerage = formSchema.brokerage;
        this.formFields.notaryFees = formSchema.notaryFees;
        this.formFields.registryFees = formSchema.registryFees;
      }
    },

    updateFormFields() {
      this.updateCostsFields();
      this.calculateValue();
    },

    showOperationOptions() {
      this.$refs.operation.activateMenu();
    },

    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    submitForm() {
      this.$emit('submit', this.formFields);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  background-color: #fff;
  color: gray;
  opacity: 1;
  box-shadow: 2px 2px 10px #00000020;
  border: solid 2px #eee;
  line-height: 20pt;
  margin-top: -15px;
}
</style>
