<template>
  <v-card>
    <v-card-title class="justify-center white--text primary">
      {{ title }}
    </v-card-title>

    <br />

    <v-card-text class="py-0">
      <v-row class="pb-4">
        <v-col cols="9" class="pa-0" align-items="end">
          <v-file-input
            :loading="loading"
            @change="onInputChange"
            label="Selecionar CSV"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </v-col>

        <v-col cols="3" align-content="end">
          <v-btn text color="primary" @click="downloadExample">
            <v-icon small class="mr-1"> mdi-file-document-outline </v-icon> Baixar exemplo
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="mappedHeaders"
        :items="items"
        :items-per-page="5"
        class="elevation-1"
        :loading="loading"
      />

      <v-container>
        <v-row class="my-3" dense justify="end">
          <v-col cols="9" sm="auto">
            <v-btn text block color="error" :loading="loading" @click="handleCancel">
              Cancelar
            </v-btn>
          </v-col>

          <v-col cols="9" sm="auto">
            <v-btn @click="handleSubmit" :loading="loading" :disabled="items.length === 0">
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-snackbar v-model="showErrorSnackbar" color="error" timeout="5000">
      <v-icon small class="mr-1"> mdi-alert-circle-outline </v-icon>
      Erro ao ler o arquivo
    </v-snackbar>
  </v-card>
</template>

<script>
import Papa from 'papaparse';

export default {
  name: 'BatchOperationsDialog',

  props: {
    title: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    items: [],
    loading: false,
    showErrorSnackbar: false,
  }),

  computed: {
    mappedHeaders() {
      return this.headers.map((header) => ({
        text: header,
        value: header,
      }));
    },
  },

  methods: {
    handleCancel() {
      this.$emit('click:cancel');
    },
    handleSubmit() {
      this.$emit('click:submit', this.items);
      this.$emit('click:cancel');
    },
    downloadExample() {
      const csv = Papa.unparse({
        fields: this.headers,
        data: [this.headers.map(() => '')],
      });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'example.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onInputChange(value) {
      const file = value;
      if (!file) {
        this.items = [];
        return;
      }
      this.loading = true;
      Papa.parse(file, {
        worker: true,
        header: true,
        skipEmptyLines: true,
        complete: ({ data, errors }) => {
          this.loading = false;

          if (errors.length > 0) {
            this.showErrorSnackbar = true;
            return;
          }

          // we only allow values that are in the this.headers
          // we need to clean the array of objects data to only have the headers we want
          const items = data.map((row) => {
            const obj = {};
            Object.keys(row).forEach((key) => {
              if (this.headers.includes(key)) {
                obj[key] = row[key];
              }
            });
            return obj;
          });

          this.items = items.filter((item) => {
            const values = Object.values(item);
            return values.some((v) => v !== '');
          });
        },
        error: () => {
          this.loading = false;
          this.showErrorSnackbar = true;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  background-color: #fff;
  color: gray;
  opacity: 1;
  box-shadow: 2px 2px 10px #00000020;
  border: solid 2px #eee;
  line-height: 20pt;
  margin-top: -15px;
}
</style>
