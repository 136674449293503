import { sumBy, uniq } from 'lodash';
import { getSignedValue } from './common-utils';

type FutureOptionOperation = Record<string, any>;

export function calculateFutureOptionCosts(item: FutureOptionOperation) {
  const {
    brokerage = 0,
    registryFees = 0,
    notaryFees = 0,
  } = item;

  return brokerage + registryFees + notaryFees;
}

export function getFutureOptionsOverview(operations: FutureOptionOperation[]) {
  return {
    totalCount: operations.length,
    totalQuantity: sumBy(operations, (item) => getSignedValue(item.quantity ?? 0, item.operation)),
    totalCosts: sumBy(operations, (item) => calculateFutureOptionCosts(item)),
    totalValue: sumBy(operations, 'value'),
  };
}

export function getFutureOptionsFilterOptions(operations: FutureOptionOperation[]) {
  return {
    ticker: uniq(operations.map((item) => item.ticker)).sort(),
    book: uniq(operations.map((item) => item.book)).sort(),
    operation: uniq(operations.map((item) => item.operation)).sort(),
  };
}

export function filterFutureOptionsResults(results: Record<string, any>[], filters: Record<string, any>) {
  return results.map((row) => {
    const operationsArray = row.operationsArray.filter((operation: Record<string, any>) => {
      const rules: boolean[] = [
        !filters.ticker.length || filters.ticker.includes(operation.ticker),
        !filters.book.length || filters.book.includes(operation.book),
        !filters.operation.length || filters.operation.includes(operation.operation),
      ];

      return rules.every((rule) => rule);
    });

    return {
      ...row,
      operationsArray,
      count: operationsArray.length,
      totalValue: sumBy(operationsArray, 'value'),
      currencies: uniq(operationsArray.map((item: any) => item.currency)),
    };
  }).filter((item) => item.operationsArray.length);
}
