<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="key"
    show-expand
  >
    <template v-slot:item.registerDate="{ item }">
      {{ formatDate(item.registerDate) }}
    </template>

    <template v-slot:item.count="{ item }">
      {{ formatNumber(item.count) }}
    </template>

    <template v-slot:item.totalValue="{ item }">
      <span :class="{ 'error--text': item.totalValue < 0 }">
        {{ formatMoney(item.totalValue) }}
      </span>
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-3">
        <slot name="details" :item="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate, formatMoney, formatNumber } from '@/utils/format-utils';

export default {
  name: 'FundsOperationsTable',

  inheritAttrs: false,

  data: () => ({
    headers: [
      {
        value: 'name',
        text: 'Fundo',
      },

      {
        value: 'registerDate',
        text: 'Data de registro',
      },

      {
        value: 'count',
        text: 'Nº operações',
        align: 'end',
      },

      {
        value: 'totalValue',
        text: 'Valor total',
        align: 'end',
      },

      {
        value: 'data-table-expand',
        text: '',
      },
    ],
  }),

  methods: {
    formatDate,
    formatMoney,
    formatNumber,
  },
};
</script>
