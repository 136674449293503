import { sumBy, uniq } from 'lodash';
import { formatDate } from '@/utils/format-utils';
import { getSignedValue } from './common-utils';

type CashSettlement = Record<string, any>;

export function getCashSettlementsOverview(operations: CashSettlement[]) {
  return {
    totalCount: operations.length,
    totalQuantity: sumBy(operations, (item) => getSignedValue(item.quantity ?? 0, item.operation)),
    totalValue: sumBy(operations, 'value'),
  };
}

export function getCashSettlementsFilterOptions(operations: CashSettlement[]) {
  return {
    origin: uniq(operations.map((item) => item.origin)).sort(),
    description: uniq(operations.map((item) => item.description)).sort(),
    settlementDate: uniq(operations.map((item) => item.settlementDate)).sort().reverse().map(
      (value) => ({ value, text: formatDate(value) }),
    ),
    refDate: uniq(operations.map((item) => item.refDate)).sort().reverse().map(
      (value) => ({ value, text: formatDate(value) }),
    ),
  };
}

export function filterCashSettlementsResults(results: Record<string, any>[], filters: Record<string, any>) {
  return results.map((row) => {
    const operationsArray = row.operationsArray.filter((operation: Record<string, any>) => {
      const rules: boolean[] = [
        !filters.origin.length || filters.origin.includes(operation.origin),
        !filters.description.length || filters.description.includes(operation.description),
        !filters.settlementDate?.length || filters.settlementDate.includes(operation.settlementDate),
        !filters.refDate?.length || filters.refDate.includes(operation.refDate),
      ];

      return rules.every((rule) => rule);
    });

    return {
      ...row,
      key: `${row.houseFundId}_${row.refDate ?? row.settlementDate}`,
      operationsArray,
      count: operationsArray.length,
      totalValue: sumBy(operationsArray, 'value'),
      currencies: uniq(operationsArray.map((item: any) => item.currency)),
    };
  }).filter((item) => item.operationsArray.length);
}
