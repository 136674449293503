<template>
  <v-form ref="form" v-model="isFormValid" :disabled="loading" @submit.prevent="() => submitForm()">
    <v-container fluid>
      <v-row class="mb-5">
        <v-col cols="12" sm="4">
          <house-funds-selector
            label="Fundo da Casa"
            hint="Clique para selecionar"
            append-icon="mdi-home-currency-usd"
            persistent-hint
            autofocus
            item-text="shortName"
            item-value="_id"
            v-model="formFields.houseFundId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            label="Operação"
            hint="Clique para selecionar"
            append-icon="mdi-swap-horizontal-bold"
            ref="operation"
            persistent-hint
            v-model="formFields.operation"
            :items="operationTypeOptions"
            @input="suggestOperationDates"
            @focus="showOperationOptions"
            @mousedown.prevent
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Book"
            append-icon="mdi-book-variant"
            v-model="formFields.book"
            v-maska="vMask.alphanumericUnderscored"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12">
          <funds-selector
            label="Fundo Investido"
            hint="Nome ou CNPJ do fundo"
            persistent-hint
            return-object
            item-value="_id"
            item-text="shortName"
            v-model="formFields.fundId"
            :rules="[vRules.requiredField]"
            @input="suggestOperationDates"
            @change="selectedFund = $event"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Operação"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.registerDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
            @input="suggestOperationDates"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Cotização"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.refDate"
            :key="minAllowedDates.refDate"
            :rules="[
              vRules.requiredField,
              vRules.validDate,
              vRules.minDateLimit(minAllowedDates.refDate),
            ]"
            :picker-props="{ allowedDates: getAllowedDates, min: minAllowedDates.refDate }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Liquidação"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.settlementDate"
            :key="minAllowedDates.settlementDate"
            :rules="[
              vRules.requiredField,
              vRules.validDate,
              vRules.minDateLimit(minAllowedDates.settlementDate),
            ]"
            :picker-props="{ allowedDates: getAllowedDates, min: minAllowedDates.settlementDate }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Quantidade"
            append-icon="mdi-counter"
            :rules="isFieldDisabled.quantity ? [] : [vRules.requiredField]"
            :disabled="isFieldDisabled.quantity"
            :options="vOptions.positive"
            :value="formFields.quantity"
            @input="formFields.quantity = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Cota Predefinida"
            hint="Somente caso cota pré-acordada"
            append-icon="mdi-cash-check"
            persistent-hint
            :disabled="isFieldDisabled.predefinedPrice"
            :options="vOptions.positive"
            :value="formFields.predefinedPrice"
            @input="formFields.predefinedPrice = vMask.unmaskNumber($event)"
            @blur="calculateQuantity"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Valor"
            append-icon="mdi-cash-multiple"
            :rules="isFieldDisabled.value ? [] : [vRules.requiredField]"
            :disabled="isFieldDisabled.value"
            :options="valueFieldOptions"
            :key="formFields.operation"
            :value="formFields.value"
            @input="formFields.value = vMask.unmaskNumber($event)"
            @blur="calculateQuantity"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn text block color="error" :disabled="loading" @click="() => discardHandler()">
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Decimal from 'decimal.js';
import { isNil } from 'lodash';
import moment from 'moment-loyall';
import { isISO8601 } from 'validator';

import fillSchema from '@/utils/fill-schema';
import { alphanumericUnderscored, unmaskNumber } from '@/utils/mask-utils';
import { parseDate } from '@/utils/parse-utils';
import { minDateLimit, requiredField, validDate } from '@/utils/validators';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import FundsSelector from '@/components/global/FundsSelector.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  houseFundId: null,
  operation: 'BUY',
  book: '',

  fundId: null,

  registerDate: null,
  refDate: null,
  settlementDate: null,

  quantity: null,
  predefinedPrice: null,
  value: null,
};

const isValidDateStr = (date) => date?.length === 10 && isISO8601(date, { strict: true });

export default {
  name: 'FundsOperationsForm',

  components: {
    DatePickerFieldV2,
    FundsSelector,
    HouseFundsSelector,
    NumericInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },
  },

  data: (vm) => ({
    isFormValid: false,
    selectedFund: null,

    formFields: fillSchema(formSchema, vm.initialData),

    vRules: {
      requiredField,
      validDate,
      minDateLimit,
    },

    vMask: {
      alphanumericUnderscored,
      unmaskNumber,
    },

    vOptions: {
      value: {
        autoDecimalDigits: true,
        precision: 2,
      },

      negative: {
        valueRange: { max: 0 },
      },

      positive: {
        valueRange: { min: 0 },
      },
    },

    operationTypeOptions: [
      { value: 'BUY', text: 'Aplicação' },
      { value: 'SELL', text: 'Resgate' },
      { value: 'TOTAL_REDEMPTION', text: 'Resgate Total' },
      { value: 'DEPOSIT', text: 'Depósito' },
      { value: 'WITHDRAWAL', text: 'Retirada' },
      { value: 'PAYOUT', text: 'Pagamento' },
    ],
  }),

  watch: {
    initialData: {
      immediate: true,
      deep: true,
      handler(updatedInitialData) {
        this.formFields = fillSchema(formSchema, updatedInitialData);
      },
    },

    selectedFund(newSelection) {
      const { _id } = newSelection ?? {};

      this.formFields = fillSchema(formSchema, {
        ...this.formFields,
        fundId: _id,
      });
    },

    currOperation: {
      immediate: true,
      handler() {
        this.clearDisabledFields();
      },
    },
  },

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText || 'Descartar alterações',
      submitBtn: vm.submitBtnText || 'Enviar',
    }),

    isFieldDisabled: (vm) => ({
      value: vm.formFields.operation === 'TOTAL_REDEMPTION',
      quantity: !['DEPOSIT', 'WITHDRAWAL'].includes(vm.formFields.operation),
      predefinedPrice: !['BUY', 'SELL'].includes(vm.formFields.operation),
    }),

    valueFieldOptions: (vm) => ({
      ...vm.vOptions.value,
      ...(['BUY', 'WITHDRAWAL'].includes(vm.formFields.operation)
        ? vm.vOptions.negative
        : vm.vOptions.positive),
    }),

    operationCalendar: (vm) => {
      if (vm.formFields.operation === 'BUY') return vm?.selectedFund?.purchase ?? {};
      if (['SELL', 'TOTAL_REDEMPTION'].includes(vm.formFields.operation)) return vm?.selectedFund?.withdrawal ?? {};
      return {};
    },

    minAllowedDates: (vm) => {
      const fallbackDate = '1970-01-01';
      const { refDate, registerDate, operation } = vm.formFields;
      const isRedemption = ['SELL', 'TOTAL_REDEMPTION'].includes(operation);
      const settlementBaseDate = isRedemption ? refDate ?? registerDate : registerDate;
      return {
        refDate: isValidDateStr(registerDate) ? registerDate : fallbackDate,
        settlementDate: isValidDateStr(settlementBaseDate) ? settlementBaseDate : fallbackDate,
      };
    },

    currOperation: (vm) => vm.formFields.operation,
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    calculateDate(baseDate, daysUntil, daysType) {
      if (!isValidDateStr(baseDate) || isNil(daysUntil)) return null;

      const expectedDate = daysType === 'BUSINESS_DAYS'
        ? moment(baseDate).businessAdd(daysUntil, 'd', 'brasil')
        : moment(baseDate).add(daysUntil, 'd');

      const adjustedDate = expectedDate.isBusinessDay('brasil')
        ? expectedDate
        : expectedDate.businessAdd(1, 'd', 'brasil');

      return parseDate(adjustedDate);
    },

    suggestOperationDates() {
      const {
        daysToConvertion, daysToConvertionType, daysToSettlement, daysToSettlementType,
      } = this.operationCalendar;

      if (this.formFields.registerDate && !this.formFields.refDate) {
        this.formFields.refDate = this.calculateDate(
          this.formFields.registerDate,
          daysToConvertion,
          daysToConvertionType,
        );
      }

      if (this.formFields.refDate && !this.formFields.settlementDate) {
        this.formFields.settlementDate = this.calculateDate(
          this.formFields.refDate,
          daysToSettlement,
          daysToSettlementType,
        );
      }
    },

    clearDisabledFields() {
      Object.keys(this.formFields).forEach((field) => {
        if (this.isFieldDisabled[field]) {
          this.formFields[field] = formSchema[field];
        }
      });
    },

    calculateQuantity() {
      this.formFields.quantity = this.formFields.predefinedPrice && this.formFields.value
        ? Decimal(this.formFields.value).abs().div(this.formFields.predefinedPrice)
        : null;
    },

    showOperationOptions() {
      this.$refs.operation.activateMenu();
    },

    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    submitForm() {
      this.$emit('submit', {
        ...this.formFields,
        value: this.formFields.value ?? 0,
      });
    },
  },
};
</script>
