<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <house-funds-selector
            label="Fundo"
            hint="Campo obrigatório"
            persistent-hint

            item-text="shortName"
            item-value="_id"

            v-model.trim="formFields.houseFundId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <date-picker-field-v2
            label="Data (refDate)"
            :hint="formFields.refDate ? 'Utilize o formato YYYY-MM-DD' : 'Campo obrigatório'"
            :persistent-hint="!formFields.refDate"

            v-model.trim="formFields.refDate"
            :rules="[vRules.requiredField, vRules.validDate]"

            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            label="Ativo"

            v-maska="vMask.uppercased"
            v-model.trim="formFields.ticker"
            :error-messages="shareFieldsErrorBag"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="ShareId"

            v-model.trim="formFields.shareId"
            :error-messages="shareFieldsErrorBag"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            label="Book"

            v-maska="vMask.uppercased"
            v-model.trim="formFields.book"
          />
        </v-col>

        <v-col cols="12" sm="8">
          <v-text-field
            label="Código corretora"
            hint="Campo obrigatório"
            persistent-hint

            v-model.trim="formFields.brokerId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Número de contrato"

            v-model="formFields.contractNumber"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <v-select
            label="Tipo de operação"

            v-model.trim="formFields.operation"
            :items="operationTypeOptions"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <date-picker-field-v2
            label="Liquidação"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.settlementDate"
            :rules="[vRules.validDate]"

            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <date-picker-field-v2
            label="Vencimento"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.loanDueDate"
            :rules="[vRules.validDate]"
            :error-messages="loanDueDateErrorBag"

            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            label="Operação de origem"

            v-model="formFields.affectedHolding"
            :error-messages="affectedHoldingErrorBag"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="Quantidade"

            :value="formFields.quantity"
            @input="formFields.quantity = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="PU Base"

            :value="formFields.refPrice"
            @input="formFields.refPrice = vMask.unmaskNumber($event)"
            :options="{ valueRange: { min: 0 } }"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="Valor a liquidar"

            :value="formFields.value"
            @input="formFields.value = vMask.unmaskNumber($event)"
            :options="{ autoDecimalDigits: true, precision: 2 }"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <numeric-input
            label="Taxa anual"
            suffix="%"
            hint="Campo obrigatório"
            persistent-hint

            :value="formFields.annualFee"
            :rules="[vRules.requiredField]"
            @input="formFields.annualFee = vMask.unmaskNumber($event)"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="loading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { round } from 'lodash';
import moment from 'moment-loyall';

import { requiredField, validDate } from '@/utils/validators';
import { unmaskNumber, uppercased } from '@/utils/mask-utils';
import fillSchema from '@/utils/fill-schema';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  houseFundId: null,
  refDate: null,

  ticker: '',
  book: '',
  operation: 'LENDING',
  brokerId: null,
  shareId: null,
  settlementDate: null,
  loanDueDate: null,
  affectedHolding: null,
  contractNumber: '',

  quantity: null,
  refPrice: null,
  value: null,
  annualFee: 0,
  // currency: 'BRL',
};

export default {
  name: 'LoanOperationsForm',

  components: {
    DatePickerFieldV2,
    HouseFundsSelector,
    NumericInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData),
    isFormValid: false,

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      uppercased,
      unmaskNumber,
    },

    operationTypeOptions: [
      'LENDING',
      'BORROWING',
      'LENDING_RETURN',
      'BORROWING_RETURN',
    ],
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText || 'Descartar alterações',
      submitBtn: vm.submitBtnText || 'Enviar',
    }),

    isReturnOperation: (vm) => ['LENDING_RETURN', 'BORROWING_RETURN'].includes(vm.formFields.operation),

    shareFieldsErrorBag: (vm) => {
      const errorBag = [];

      if (!vm.formFields.ticker && !vm.formFields.shareId) {
        errorBag.push('Preencha ao menos um campo sobre o ativo');
      }

      return errorBag;
    },

    loanDueDateErrorBag: (vm) => {
      const errorBag = [];

      if (!vm.isReturnOperation && !vm.formFields.loanDueDate) {
        errorBag.push('Campo obrigatório');
      }

      return errorBag;
    },

    affectedHoldingErrorBag: (vm) => {
      const errorBag = [];

      if (vm.isReturnOperation && !vm.formFields.affectedHolding) {
        errorBag.push('Campo obrigatório');
      }

      return errorBag;
    },
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    submitForm() {
      const parseOptionalNumber = (val) => ((!val && val !== 0) ? null : Number(val));
      const parseOptionalPercentage = (val) => ((!val && val !== 0) ? null : round(val / 100, 10));

      this.$emit('submit', {
        ...this.formFields,

        quantity: parseOptionalNumber(this.formFields.quantity),
        price: parseOptionalNumber(this.formFields.price),
        value: parseOptionalNumber(this.formFields.value),
        annualFee: parseOptionalPercentage(this.formFields.annualFee),
      });
    },
  },
};
</script>
