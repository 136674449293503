<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <house-funds-selector
            label="Fundo da Casa"
            hint="Clique para selecionar"
            append-icon="mdi-home-currency-usd"
            persistent-hint
            autofocus

            item-text="shortName"
            item-value="_id"

            v-model="formFields.houseFundId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Descrição"
            append-icon="mdi-text-box"

            v-model.trim="formFields.description"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <date-picker-field-v2
            label="Data da Operação"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.refDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <date-picker-field-v2
            label="Data da Liquidação"
            hint="Utilize o formato YYYY-MM-DD"

            v-model="formFields.settlementDate"
            :rules="[vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <numeric-input
            label="Valor"
            append-icon="mdi-cash-multiple"

            :value="formFields.value"
            :options="vOptions.value"
            @input="formFields.value = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            label="Moeda"
            ref="currency"
            append-icon="mdi-currency-sign"

            v-model="formFields.currency"
            :rules="[vRules.requiredField]"
            :items="currencyOptions"
            @focus="showOperationOptions"
            @mousedown.prevent
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="loading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import moment from 'moment-loyall';

import { requiredField, validDate } from '@/utils/validators';
import { unmaskNumber } from '@/utils/mask-utils';
import fillSchema from '@/utils/fill-schema';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  houseFundId: null,

  description: '',

  refDate: null,
  settlementDate: null,

  value: null,
  currency: 'BRL',

  origin: 'manual',
};

export default {
  name: 'CashSettlementsForm',

  components: {
    DatePickerFieldV2,
    HouseFundsSelector,
    NumericInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },
  },

  data: (vm) => ({
    isFormValid: false,

    formFields: fillSchema(formSchema, vm.initialData),

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
    },

    vOptions: {
      value: {
        autoDecimalDigits: true,
        precision: 2,
      },
    },

    currencyOptions: ['BRL', 'USD'],
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText || 'Descartar alterações',
      submitBtn: vm.submitBtnText || 'Enviar',
    }),
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    showOperationOptions() {
      this.$refs.currency.activateMenu();
    },

    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    submitForm() {
      this.$emit('submit', {
        ...this.formFields,
        value: Number(this.formFields.value),
      });
    },
  },
};
</script>
